.splashscreen-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.splashscreen-logo {
  width: 11rem;
  height: 11rem;
}
