body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.status::before {
  content: '';
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  margin-right: 0.5rem;
}

.status-Available::before {
  background-color: #40a900;
}

.status-InUse::before {
  background-color: #db3900;
}

.status-OutOfOrder::before {
  background-color: #b7cbd3;
}

.status-Unknown::before {
  background-color: #b7cbd3;
}

.icon-container {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}

.connector-standard {
  width: 5rem;
  text-align: center;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 0.65rem;
  letter-spacing: 0;
  line-height: 0.8rem;
  margin-bottom: 0.4rem;
}

.slider-container {
  flex: 0 0 20%;
  border-radius: 7.5px;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.illustration-size {
  height: auto;
  width: 100%;
}

.info-card {
  display: flex;
  background-color: #ffffff;
  border-radius: 5px;
}

/* Transition */
.fade-in {
  animation-name: FadeIn;
  animation-duration: 0.7s;
  transition-timing-function: linear;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes FadeIn {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes FadeIn {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes FadeIn {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes FadeIn {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

.main-container {
  padding-top: 4rem;
  height: 100%;
}

/* Overriding default scrollbar */
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #ee0000;
  border: 0px none #ffffff;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #000000;
}

::-webkit-scrollbar-track {
  background: #b7cbd385;
  border: 0px none;
  border-radius: 3px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #b7cbd3;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Recaptcha visibility */

.grecaptcha-badge {
  visibility: hidden;
}
